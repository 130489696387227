"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var base_1 = require("@/../../base");

var common_ui_1 = require("@rose/common-ui");

var authStore_1 = require("@/state/authStore");

var editSettingsRootStore_1 = require("@/state/settings/editSettingsRootStore");

exports["default"] = (0, vue_1.defineComponent)({
  data: function data() {
    return {
      currentTab: '',
      isProd: common_ui_1.isProd
    };
  },
  computed: {
    isLoading: function isLoading() {
      return editSettingsRootStore_1.editSettingsRootStore.state.isLoading;
    },
    hasMetrics: function hasMetrics() {
      return (0, base_1.hasMetricsFeature)(authStore_1.authStore.getters.profile);
    },
    hasFactoring: function hasFactoring() {
      return (0, base_1.hasR4cFactoringFeature)(authStore_1.authStore.getters.profile);
    },
    hasAnamnese: function hasAnamnese() {
      return (0, base_1.hasAnamneseFeature)(authStore_1.authStore.getters.profile);
    },
    showTabletsTab: function showTabletsTab() {
      return (0, base_1.hasAnyR4cTabletFeature)(authStore_1.authStore.getters.profile);
    }
  },
  beforeMount: function beforeMount() {
    void editSettingsRootStore_1.editSettingsRootStore.dispatch.init();
  }
});